@import "~common/stylesheet/index";

.category {
  justify-content: space-between;

  .icon {
    margin-left: 4px;
  }
}

.expandable_list_item {
  background-color: $color-shadow;
  border-bottom: 1px solid $theme-dark;
}
