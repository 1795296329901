$theme-dark: #242424;
$theme-normal: #393939;
$theme-light: #505050;
$color-font: #bbbbbb;
$color-shadow: rgba(#000000, .2);
$color-overlay: rgba(#ffffff, .1);
$color-alert: #f3bd58;
$color-selected: #2962ff;
$color-patched: #c51162;
$color-highlight: #29d;
$color-active: #00e676;

:export {
  themeDark: $theme-dark;
  themeNormal: $theme-normal;
  themeLight: $theme-light;
  colorFont: $color-font;
  colorShadow: $color-shadow;
  colorOverlay: $color-overlay;
  colorAlert: $color-alert;
  colorSelected: $color-selected;
  colorPatched: $color-patched;
  colorHighlight: $color-highlight;
  colorActive: $color-active;
}
